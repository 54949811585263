<template>
<div class="page-reporte-stock-general">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Consulta de Stock por Proveedores" class="elevation-1 px-5 py-3">

      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="3" md="3" lg="3">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" :rules="[rules.required]"
                    item-text="nombre" item-value="_id" label="Sucursal"></v-autocomplete>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-autocomplete v-model="model.proveedor" :items="proveedores" :rules="[rules.required]"
                  chips small-chips deletable-chips
                  hide-no-data hide-selected item-text="nombre" item-value="nombre" label="Proveedor"
                  :hide-no-data="true" :hide-selected="true"
                        ></v-autocomplete>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-autocomplete v-model="model.categoria" :items="categorias" :hide-no-data="true"
                  :hide-selected="true" item-text="nombre" multiple chips small-chips deletable-chips
                  item-value="nombre" label="Categoría"
                  >
                </v-autocomplete>
              </v-col>
              <v-col sm="3" md="3" lg="3">
                <v-autocomplete
                        v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                        :loading="isLoading" ref="selectarticulo"
                        :search-input.sync="search_articulo"
                        placeholder="Ingrese nombre de artículo para buscar"
                        prepend-icon="search" clearable
                        item-text="nombre" item-value="_id"
                        label="Artículo">
                    </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="4" md="4" lg="4">
                <v-switch v-model="model.inactivo" label="Incluir Inactivos" true-value="1" false-value="0"></v-switch>
              </v-col>
              <v-col sm="4" md="4" lg="4">

              </v-col>
              <v-col sm="4" md="4" lg="4">

              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="model = clean_model()">
          <v-icon>cancel</v-icon> Limpiar Filtros
        </v-btn>
        <v-btn color="success" @click.native="generar_reporte()">
          <v-icon>done</v-icon> {{ "Generar Reporte" }}
        </v-btn>
      </v-card-actions>


        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

</div>
</template>

<script>
export default {
  components: {
  },
  watch: {
    model_filters: {
      handler(val) {
        this.$nextTick(() => {
          this.items = [];
          this.$refs.pagination_pw.$refs.infiniteLoading.$emit('$InfiniteLoading:reset');
        });
      },
      deep: true
    },
    searchProveedor(val) {
      //Si no ha escrito 2 letras o mas
      if (val == null || val.length<2 ) return

      let data = {
                  "selector": {
                      "type":"proveedores",
                      "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                      "estatus": "Activo"
                  },
                  "sort": ["nombre"],
              };

          window.axios
              .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
              .then(response => {
                  if (response.data.docs.length > 0){
                      this.proveedores = response.data.docs;
                  }else
                      this.proveedores = [];
              })
              .catch(error => {
                  this.$swal({
                      type: "error",
                      title: "¡Operación no Permitida!",
                      text: "Ocurrió un error al obtener los proveedores.",
                      footer: ""
                  });
              }).then(()=>{});
    },
    search_articulo(val) {
        //Si no ha escrito 2 letras o mas
        if (val == null || val.length<2 ) return
        // Si todavía esta buscando
        if (this.isLoading) return
        this.isLoading = true
        let data = {
                    "selector": {
                        "type":"articulos",
                        "nombre": {"$regex": "(?i)" + val.toString().replace("(","\\(").replace(")","\\)").replace("+","\\+")},
                        "estatus": "Activo"
                    },
                    "fields":["_id","nombre"]
                };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        response.data.docs.sort(this.ordenar_nombre);
                        this.articulos = response.data.docs;
                    }else
                        this.articulos = [];
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(()=>{ this.isLoading=false; });
      },

  },
  mounted: function() {
    if (!this.verificaPermiso('t.inventarios.stock.proveedores')) {
      this.$router.replace("AccessDenied").catch(() => {});
    }
  },
  created: function() {
    this.model = this.clean_model();
    this.get_sucursales();
    this.getCategorias();
    this.getProveedores();
  },
  data() {
    return {
      proveedores: [],
      sucursales:[],
      search_articulo:'',
      searchProveedor:'',
      search_marca: null,
      categorias:[],
      marcas:[],
      articulos:[],
      articulos_proveedores:[],
      fab: [],
      isLoading: false,
      articulos_encontrados: [],
      id_articulo: "",

      search: "",
      model_filters: "",

      tmp_fecha: "",
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Reportes",
          disabled: true,
          href: ""
        },
        {
          text: "Stock por Proveedores",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "rfc",
        "telefono_1",
        "contacto",
        "email",
        "fecha_alta",
        "estatus",
        "actions"
      ],
      options: {
        filterable: ["nombre", "rfc", "telefono_1", "contacto", "email", "estatus"],
        sortable: ["nombre", "rfc", "telefono_1", "contacto", "email", "fecha_alta", "estatus"],

        headings: {
          nombre: "Nombre",
          rfc: "RFC",
          telefono_1: "Telefono",
          contacto: "Contacto",
          email: "Email",
          fecha_alta: "Fecha Alta",
          estatus: "Estatus",
          actions: "Operaciones"
        },
      },
      modal: false,
      modal_articulos: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
        decimal: value => {
          const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
          return pattern.test(value) || "Número decimal no válido";
        },
        entero: function(numero) {
          const pattern = /^[\d]*$/;
          return pattern.test(numero) || "Debe ser un número entero";
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no valido";
        },
        rfc: v =>
          (v && v.length >= 9 && v.length < 15) || "RFC debe tener al menos 9 caractéres y 14 caractéres"
      },
      items: []
    }
  },
  methods: {
    ordenar_nombre: function( a, b ){
            if ( a.nombre.toLowerCase() < b.nombre.toLowerCase()){
                return -1;
            }
            if ( a.nombre.toLowerCase() > b.nombre.toLowerCase()){
                return 1;
            }
            return 0;
        },
    get_sucursales: function () {
        let data = {
            "selector": {
                "type":"sucursales",
                "estatus": {
                    "$eq": "Activo"
                }
            },
            "fields": [
                "nombre", "_id"
            ]
        };

        window.axios
            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
            .then(response => {
                this.sucursales = [];
                if (response.data.docs.length > 0)
                    this.sucursales = response.data.docs;
            })

            .catch(error => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al obtener las sucursales.",
                    footer: ""
                });
            });
    },
    getCategorias: function() {
      let data = {
        "selector": {
          "type":"categorias",
          "estatus": {
            "$eq": "Activo"
          }
        },
        "fields": [
          "nombre", "_id"
        ]
      };
      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.categorias = response.data.docs;
          else
            this.categorias = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener las categorias.",
            footer: ""
          });
        });
    },
    getProveedores: function(el) {
      let data = {
        "selector": {
          "type":"proveedores",
          "estatus": "Activo",
        },
        "fields": [
          "nombre", "_id"
        ]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0)
            this.proveedores = response.data.docs;
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los proveedores.",
            footer: ""
          });
        });
    },
    clean_model: function() {
      return {
        categoria: [],
        marca: [],
        id_articulo: '',
        inactivo: "0",
        id_sucursal:"",
        proveedor:[]
      };
    },
    generar_reporte: function(){
      try {
          if(this.$refs.form.validate()){
            if(this.model.proveedor == '' || this.model.proveedor == null || this.model.proveedor == undefined) {
              this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "Debes seleccionar al menos un proveedor",
                  footer: ""
              });
              return;
            }
              window.dialogLoader.show('Espere un momento por favor..');

            if(this.model.id_articulo == null)
                 this.model.id_articulo = '';

                window.axios
                    .post(process.env.VUE_APP_REPORTES_URL + "/reportes/rpt_stock_proveedores/", this.model, {
                        "Content-type": "application/json",
                        "Access-Control-Allow-Origin": "*",
                        "Allow": "POST",
                        "cache-control": "no-cache",
                        "responseType": "arraybuffer"
                    })
                    .then(response => {
                        var bytes = response.data;
                        let blob = new Blob([bytes], {
                                type: response.headers["content-type"]
                            }),
                            url = window.URL.createObjectURL(blob);

                        var a = document.createElement("a");
                        document.body.appendChild(a);
                        a.style = "display: none";
                        a.href = url;
                        a.download = "Stock General.xlsx";
                        a.click();
                        a.parentNode.removeChild(a);
                    })
                    .catch(error => {
                    console.log(error);
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al generar el reporte. Intente nuevamente.",
                        footer: ""
                    });
                    }).then(()=>{
                    window.dialogLoader.hide();
                    });
          }

        } catch (error) {
          console.log("ERRORS: ",error);
        }
    },



  }
}
</script>
